'use client';
import React from "react";
import "@/scss/errorPage.scss";
import Image from "next/image";
import Link from "next/link";

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  return (
    <div className="ffErrorPageContent">
      <h1>404</h1>
      <p>Oops! Looks like that link didn’t work right now...</p>
      <p>
        <Link href="#">Go back</Link>
      </p>
      <Image
        src={`/assets/svg/errorPagePic.svg`}
        alt="Error Image"
        width={800}
        height={600}
        priority={true}
      />
    </div>
  );
};

export default Error;

export async function generateMetadata() {
  return {
    title: "FemaleFirst Page Not Found",
    description: "FemaleFirst Page Not Found",
    keywords: "FemaleFirst Page Not Found",
  };
}
